<template>
  <div class="stack">
    <div class="stack-info">
      <div class="stack-info__content">
        <page-title class="stack-info__title">
          {{ $t('title') }}
        </page-title>
        <div v-if="list.length" class="stack-mode">
          <plain-button
            icon="grid"
            class="stack-mode__btn"
            watch-active
            :active="mode === 'grid'"
            @click="setMode('grid')"
          />
          <plain-button
            icon="list"
            class="stack-mode__btn"
            watch-active
            :active="mode === 'table'"
            @click="setMode('table')"
          />
        </div>
        <!--          v-if="priceList.length && list.length && ((balance > 0 && !needPhoneValidate) || dev)"-->
      </div>
      <div
        v-if="priceList.length && list.length"
        :class="{ visible: list.length }"
        class="stack-info__create"
      >
        <base-button :to="{ name: 'StackOrder' }" class="stack-info__create">
          {{ $t('newServer') }}
        </base-button>
      </div>
    </div>
    <transition name="fade" mode="out-in" appear>
      <div v-if="isRequest" class="stack-info__loader">
        <base-loader class="stack-info__loader-item" />
        <!--        v-else-if="!isLoading && isServerError"-->
      </div>
      <base-alert
        v-else-if="isServerError"
        :title="$t('errors.serverError.title')"
        icon="warn"
        class="stack-info__alert"
      >
        {{ $t('errors.serverError.text') }}
      </base-alert>
      <!--        v-else-if="!isLoading && !list.length && !isServerError"-->
      <base-empty
        v-else-if="!list.length && !isServerError"
        :title="$t('empty.title')"
        :link="{
          title: $t('createServer'),
          to: { name: 'StackOrder' },
          disabled: !hideButtonCreate,
        }"
        :text="$t('empty.text')"
        :stacklink1="
          link1
            ? {
                title: $t('stacklink1.title'),
                to: { name: 'BillingPayers' },
                text: $t('stacklink1.text'),
              }
            : null
        "
        :stacklink2="
          link2
            ? {
                title: $t('stacklink2.title'),
                to: { name: 'ProfilePersonal' },
                text: $t('stacklink2.text'),
              }
            : null
        "
        :stacklink3="{
          title: $t('stacklink3.title'),
          to: { name: 'BillingDeposit' },
          text: $t('stacklink3.text'),
        }"
        class="stack-info__empty"
      />
      <div v-else class="stack-content">
        <transition name="fade" mode="out-in">
          <component :is="modeComponents[mode]" :dataset="list" />
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import updateBalance from '@/mixins/billing/updateBalance';
import MainButton from '@/components/Buttons/MainButton.vue';
import TariffsGrid from './components/TariffsGrid';
import TariffsTable from './components/TariffsTableStack';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import storeMixin from '../../mixins/index';
import phoneVerification from '@/mixins/phoneVerification';
import updatePayers from '@/mixins/updatePayers';
import updatePhone from '@/mixins/updatePhone';
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';
import updateStatus from '@/mixins/updateStatus';
export default {
  name: 'StackMain',
  components: {
    MainButton,
    TariffsGrid,
    TariffsTable,
    BaseEmpty,
    BaseAlert,
  },
  mixins: [storeMixin, updateBalance, phoneVerification, updatePayers, updateStatus, updatePhone],
  data() {
    return {
      modeComponents: {
        grid: TariffsGrid,
        table: TariffsTable,
      },
      isRequest: true,
      isServerError: false,
    };
  },
  computed: {
    dev() {
      return !IS_PROD;
    },
    balance() {
      return +this.$store.getters['moduleProfile/balance'];
    },
    mode() {
      return this.$store.state.moduleStack.mode;
    },
    link1() {
      return Object.keys(this.$store.state.moduleProfile.payers).length === 0;
    },
    link2() {
      return this.needPhoneValidate;
    },
    priceList() {
      return this.$store.state.moduleStack.moduleStackOrder.list;
    },
    hideButtonCreate() {
      return this.link1 === false && this.link2 === false && this.balance > 999;
    },
  },
  mounted() {
    // if (!this.list.length) {
    this.fetch();
    this.updateStatus('moduleStack/fetchList');
    this.updatePayersInfo();
    this.updatePhoneInfo();
    if (!this.dev) this.updateBalance();
    // this.$store.dispatch('moduleBilling/modulePayment/updateList');
    // this.fetchPayersList();
    // }
  },
  methods: {
    setMode(val) {
      this.$store.commit('moduleStack/SET_MODE', val);
    },
    fetchList() {
      return this.$store.dispatch('moduleStack/fetchList');
    },
    // fetchPayersList() {
    //   return this.$store.dispatch('moduleBilling/modulePayers/fetchList');
    // },
    fetch() {
      this.fetchList()
        .then(() => {
          this.$store.dispatch('moduleStack/moduleStackOrder/fetchPricelist');
        })
        // .then(() => this.fetchPayersList())
        // .then(() => this.updateBalance())
        // .then(() => this.updatePayersInfo())
        .then(() => {
          if (this.list.length) {
            const tariff = this.list.find(item => this.tools.gotoserver.isEnable(item));
            this.$store.dispatch('moduleStack/fetchDetail', tariff.id).catch(e => console.log(e));
            // }
          }
        })
        .catch(e => {
          if (e.status && e.status === 520) this.isServerError = true;
        })
        .finally(() => (this.isRequest = false));
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "title": "Публичное облако",
      "newServer": "Новое облако",
      "createServer": "Создать публичное облако",
      "stacklink1": {
        "title": "разделе",
        "text": "Завести плательщика в этом"
      },
      "stacklink2": {
        "title": "тут",
        "text": "Добавить верифицированный номер телефона"
      },
      "stacklink3": {
        "title": "тут",
        "text": "Пополнить баланс на сумму от 1000р"
      },
      "empty": {
        "title": "На данный момент у вас нет ни одной облачной услуги",
        "text": "Для старта нужно выполнить: ",
        "text1": "Завести плательщика в этом разделе. ",
        "text2": "Добавить верифицированный номер тут. ",
        "text3": "Пополнить баланс на сумму от 1000р "
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.stack {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        flex: 1 1 auto;
      }
    }
    &__create {
      flex: 0 0 auto;
      margin-top: 1.5rem;
      max-width: 160px;

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
