<template>
  <main-card class="tariff-card">
    <template #header>
      <!--        v-if="tariff.status.key === 'active' || tariff.id === '368550'"-->
      <router-link
        v-if="tariff.status.key === 'active'"
        :to="{ name: 'tariffStack', params: { id: tariff.id } }"
        class="tariff-card__title-high big-title"
      >
        {{ tariff.title + ' #' + tariff.id }}
      </router-link>
      <!--      <div></div>-->
      <div v-else class="tariff-card__title-off big-title">
        {{ tariff.title + ' #' + tariff.id }}
      </div>
    </template>
    <template #headerAction>
      <!--      <v-popover-->
      <!--        v-click-outside="closePopoverOnOutside"-->
      <!--        placement="bottom-end"-->
      <!--        :popper-options="popperOptions"-->
      <!--        :open="isPopoverOpen"-->
      <!--        trigger="manual"-->
      <!--        popover-class="tariff-card__popover"-->
      <!--      >-->
      <!--        <plain-button icon="more" class="tariff-card__more" @click="togglePopoverState" />-->
      <!--        <stack-context-menu  :tariff="tariff" class="tariff-card__context-menu" />-->
      <!--      </v-popover>-->
    </template>
    <template #headerEnd>
      <main-label :color="tariff.status.color" class="tariff-card__label">
        {{ $t(`status.${tariff.status.key}`) }}
      </main-label>
    </template>
    <div v-if="isActive === false && tariff.status.code !== 1" class="tariff-card__description">
      <div class="tariff-card__title medium-title">
        {{ tariff.description_os }}
      </div>
      <plain-button
        v-tooltip="{
          content: tariff.description_os ? $t('change') : $t('add'),
          // placement: 'bottom-start',
          placement: tariff.description_os ? 'top-end' : 'right-end',
          container: 'body',
        }"
        :icon="icon"
        size="medium"
        color="dim"
        tabindex="-1"
        :class="tariff.description_os ? 'tariff-card__description-btn' : ''"
        @click="isActive = true"
      />
    </div>
    <div v-else-if="isActive === true" class="tariff-card__edit">
      <InputText v-model="newDescription" type="text" autofocus focus class="p-inputtext-sm" />
      <Button
        icon="pi pi-check"
        class="p-button-success"
        :disabled="newDescription === tariff.description_os"
        @click="updateDescription(newDescription)"
      />
      <Button icon="pi pi-times" class="p-button-danger" @click="cancelUpdateDescription()" />
    </div>
    <div v-else>
      <label class="typo__label">Услуга активируется</label>
    </div>
    <div class="tariff-card__content">
      <div class="tariff-card__activity">
        <label class="typo__label">{{ $t('actiovationflag.text') }}</label>
        <plain-button
          v-tooltip="{
            autoHide: false,
            content: $t('actiovationflag.hint'),
            placement: 'top-start',
            container: 'body',
          }"
          icon="help"
          size="small"
          color="dim"
          tabindex="-1"
          class="amount-block__hint"
        />
        <div class="tariff-card__prolong">
          <tumbler
            :value="tariff.actiovationflag"
            class="theme-switcher__tumbler"
            @change="setActivationFlag"
          />
          <main-label
            :color="tariff.actiovationflag === true ? 'success' : 'error'"
            theme="plain"
            class="tariffs-table-item__status"
          >
            {{ tariff.actiovationflag === true ? $t('on') : $t('off') }}
          </main-label>
        </div>
      </div>
    </div>
    <div class="tariff-card__content">
      <div class="tariff-card__activity">
        <div class="tariff-card__note note-text">
          {{ $t(`created`, { date: createdate }) }}
        </div>
        <plain-button
          color="primary"
          class="tariff-card__prolong"
          :to="{
            name: tariff.status.key === 'active' ? 'Statistic' : 'StatisticOffline',
            params: { id: tariff.id },
          }"
        >
          {{ $t('static') }}
        </plain-button>
      </div>
    </div>
  </main-card>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard.vue';
import MainLabel from '@/components/Labels/MainLabel.vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import popoverFix from '@/mixins/popoverFix';
import Tumbler from '@/components/BaseTumbler/Tumbler';
export default {
  name: 'TariffCard',
  components: { MainCard, Tumbler, MainLabel, Button, InputText /*, StackContextMenu*/ },
  mixins: [popoverFix],
  props: {
    tariff: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      icon: 'edit',
      isActive: false,
      newDescription: '',
      popperOptions: {
        modifiers: {
          flip: {
            padding: { top: 60 },
          },
        },
      },
    };
  },
  computed: {
    isProlongAvailable() {
      return this.tariff.prolong_available === 'on';
    },
    description() {
      return this.newDescription ? this.newDescription : this.tariff.description_os;
    },
    createdate() {
      return this.$d(this.tariff.createdate, 'short');
    },
    isSuspended() {
      return this.tariff.employeesuspend === 'on';
    },
    isAbuseSuspended() {
      return this.tariff.abusesuspend === 'on';
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.popperOptions.modifiers.flip.boundary = this.$parent.$el;
    });
    if (this.$parent.$children.findIndex(i => i === this) < 2) {
      this.popperOptions.modifiers.flip.enabled = false;
    }
    this.newDescription = this.tariff.description_os;
  },
  methods: {
    async updateDescription(descr) {
      this.newDescription = '';
      const params = {
        elid: this.tariff.id,
        description_os: descr,
      };
      this.$store
        .dispatch('moduleStack/updateTariff', params)
        .then(() => {
          this.showSuccessModal(this.$t('success'));
          this.isActive = false;
          this.fetchList();
          this.newDescription = descr;
        })
        .catch(e => {
          this.isActive = false;
          this.newDescription = this.tariff.description_os;
          this.showError(e);
        });
    },
    fetchList() {
      return this.$store.dispatch('moduleStack/fetchList');
    },
    setActivationFlag(value) {
      const params = {
        actiovationflag: value === true ? 'on' : 'off',
        elid: this.tariff.id,
      };
      this.$store
        .dispatch('moduleStack/updateTariff', params)
        .then(() => {
          // this.showSuccessModal(this.$t('actiovationflag.success'));
          this.fetchList();
        })
        .catch(e => {
          this.showError(e);
        });
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text,
      });
    },
    cancelUpdateDescription() {
      this.isActive = false;
      this.newDescription = this.tariff.description_os;
    },
    // goToStatic() {
    //   return this.$router.push({
    //     name: 'Static',
    //     params: { id: this.tariff.id },
    //   });
    // },
    // goToWindow() {
    //   this.$store
    //     .dispatch('moduleStack/fetchBillMgrToolAction', {
    //       func: 'gotoserver',
    //       elid: this.tariff.id,
    //     })
    //     .then(data => {
    //       if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
    //         window.open(data.ok.v);
    //       }
    //     });
    // },
  },
};
</script>
<i18n>
{
  "ru": {
    "created": "Создан {date}",
    "on": "Включено",
    "off": "Выключено",
    "actiovationflag": {
      "text": "Автовключение услуги",
      "success": "Изменение прошло успешно",
      "hint": "Автоматическое включение услуги при положительном балансе"
    },
    "static": "Статистика и расходы",
    "success": "Описание изменено.",
    "prolong": "Продлить",
    "add": "Добавить описание",
    "change": "Изменить описание"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-card {
  min-height: 100%;

   &__title {
    text-decoration: none;
    color: inherit;

    &-high {
      text-decoration: none;
      color: $primary-color;

      &:hover {
        color: darken($primary-color, 20%);
      }
    }
    &-off {
      text-decoration: none;
      color: $note-color;

      &:hover {
        color: darken($note-color, 20%);
      }
    }
  }
  &__ip {
    margin-top: 2px;
    +breakpoint(sm-and-up) {
      margin-top: 20px;
    }
  }
  &__label {
    +breakpoint(sm-and-up) {
      margin-top: 0.25rem;
    }
  }
  &__description {
    display: flex;

    +breakpoint(sm-and-up) {
      margin-top: 0.75rem;
      margin-bottom: 1.15rem;
    }
    &-btn {
      margin-left: 0.5rem;
    }
  }
  &__edit {
    display: flex;

    +breakpoint(sm-and-up) {
      //margin-top: 0.75rem;
      margin-bottom: 0.75rem;
    }
    &-btn {
      margin-left: 0.5rem;
    }
  }
  &__content {
    +breakpoint(sm-and-up) {
      margin-top: 0.75rem;
    }
  }
  &__more {
    display: block;
    margin-right: -0.5rem;

    +breakpoint(sm-and-up) {
      margin-right: -1rem;
      margin-top: -2px;
    }
  }
  &__activity {
    +breakpoint(sm-and-up) {
      flexy(flex-start, baseline);
    }
  }
  &__prolong {
    +breakpoint(ms-and-down) {
      margin-top: 0.75rem;
    }

    +breakpoint(sm-and-up) {
      margin-left: 2.5rem !important;
    }
  }

  &__context-menu {
    width: calc(100vw - 3.25rem);

    +breakpoint(sm-and-up) {
      width: 260px;
    }
  }
}
</style>
