var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stack"},[_c('div',{staticClass:"stack-info"},[_c('div',{staticClass:"stack-info__content"},[_c('page-title',{staticClass:"stack-info__title"},[_vm._v(" "+_vm._s(_vm.$t('title'))+" ")]),(_vm.list.length)?_c('div',{staticClass:"stack-mode"},[_c('plain-button',{staticClass:"stack-mode__btn",attrs:{"icon":"grid","watch-active":"","active":_vm.mode === 'grid'},on:{"click":function($event){return _vm.setMode('grid')}}}),_c('plain-button',{staticClass:"stack-mode__btn",attrs:{"icon":"list","watch-active":"","active":_vm.mode === 'table'},on:{"click":function($event){return _vm.setMode('table')}}})],1):_vm._e()],1),(_vm.priceList.length && _vm.list.length)?_c('div',{staticClass:"stack-info__create",class:{ visible: _vm.list.length }},[_c('base-button',{staticClass:"stack-info__create",attrs:{"to":{ name: 'StackOrder' }}},[_vm._v(" "+_vm._s(_vm.$t('newServer'))+" ")])],1):_vm._e()]),_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[(_vm.isRequest)?_c('div',{staticClass:"stack-info__loader"},[_c('base-loader',{staticClass:"stack-info__loader-item"})],1):(_vm.isServerError)?_c('base-alert',{staticClass:"stack-info__alert",attrs:{"title":_vm.$t('errors.serverError.title'),"icon":"warn"}},[_vm._v(" "+_vm._s(_vm.$t('errors.serverError.text'))+" ")]):(!_vm.list.length && !_vm.isServerError)?_c('base-empty',{staticClass:"stack-info__empty",attrs:{"title":_vm.$t('empty.title'),"link":{
        title: _vm.$t('createServer'),
        to: { name: 'StackOrder' },
        disabled: !_vm.hideButtonCreate,
      },"text":_vm.$t('empty.text'),"stacklink1":_vm.link1
          ? {
              title: _vm.$t('stacklink1.title'),
              to: { name: 'BillingPayers' },
              text: _vm.$t('stacklink1.text'),
            }
          : null,"stacklink2":_vm.link2
          ? {
              title: _vm.$t('stacklink2.title'),
              to: { name: 'ProfilePersonal' },
              text: _vm.$t('stacklink2.text'),
            }
          : null,"stacklink3":{
        title: _vm.$t('stacklink3.title'),
        to: { name: 'BillingDeposit' },
        text: _vm.$t('stacklink3.text'),
      }}}):_c('div',{staticClass:"stack-content"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c(_vm.modeComponents[_vm.mode],{tag:"component",attrs:{"dataset":_vm.list}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }